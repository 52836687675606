






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";

export default Vue.extend({
  data() {
    return {
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          name: "Quản lý đơn vị vận chuyển",
          urlEnabled: true,
          search: {},
          displayFields: {
            name: {
              text: "Tên",
              sortable: true,
            },
            shopeeName: {
              text: "Shopee Name",
              sortable: true,
            },
            lazName: {
              text: "Lazada Name",
              sortable: true,
            },
            tiktokName: {
              text: "Tiktok Name",
              sortable: true,
            },
            code: {
              text: "Mã",
              sortable: true,
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          template: {
            itemActionButtons: {
              edit: {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: {},
              accounts: {
                content: {
                  icon: "mdi-view-list",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Danh sách tài khoản",
                    },
                  },
                },
                ext: {
                  makeAttrs(items) {
                    return {
                      to: `/app/shipping_companies/${items._id}/accounts`,
                    };
                  },
                },
              },
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("shippingCompanies", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("shippingCompanies", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "shippingCompanies",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("shippingCompanies", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                  required: true,
                },
              },
              shopeeName: {
                attrs: {
                  label: "Shopee Name",
                  required: true,
                },
              },
              lazName: {
                attrs: {
                  label: "Lazada Name",
                  required: true,
                },
              },
              tiktokName: {
                attrs: {
                  label: "Tiktok Name",
                  required: true,
                },
              },
              code: {
                attrs: {
                  label: "Mã",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
            },
            insertForm: {
              content: {
                fieldNames: ["name", "shopeeName", "lazName", "tiktokName", "code", "description"],
              },
            },
            editForm: {
              content: {
                fieldNames: ["name", "shopeeName", "lazName", "tiktokName", "code", "description"],
              },
            },
            delete: {
              confirmDisplayField: "name",
            },
          },
        },
      },
    };
  },
});
